import clsx from 'clsx'
import { FC } from 'react'

import { FooterMenuProps } from '@/components/FooterMenu/FooterMenuProps'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import styles from './FooterMenu.module.scss'
import { routes } from '@/config'

const FooterMenu: FC<FooterMenuProps> = ({
  heading = '',
  items = [],
  className = ''
}) => (
  <nav className={clsx(styles['footer-menu'], className)}>
    <Heading className={styles['footer-menu__heading']} level={3}>
      {heading}
    </Heading>
    <ul className={styles['footer-menu__list']}>
      {items.map(({ id, text, icon = null }) => (
        <li key={id} className={styles['footer-menu__item']}>
          <a
            title={text}
            className={styles['footer-menu__link']}
            href={routes[id.toUpperCase()]}
          >
            <>
              {icon && (
                <span className={styles['footer-menu__icon']}>
                  {<Icon name={`social/${icon}`} />}
                </span>
              )}
              <span className={styles['footer-menu__text']}>{text}</span>
            </>
          </a>
        </li>
      ))}
    </ul>
  </nav>
)
export default FooterMenu
